import fetch from "isomorphic-fetch";
import {
  ApolloClient,
  createHttpLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import AuthState from "../States/Auth";
import { Login } from "../utils/Auth";
import { setContext } from "@apollo/client/link/context";
import { Downgraded } from "@hookstate/core";
import { User } from "../Interfaces/User";
import { getUserQuery } from "../Query/User";
import AreaRoles from "../States/AreaRoles";
import AppState from "../States/AppState";
import { popoverAlert } from "../utils/Alert";

const httpLink = createHttpLink({
  uri: `${process.env.GATSBY_API_SERVER}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = AuthState.token.get();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

if (AuthState.token.get()) {
  Login(AuthState.attach(Downgraded).get(), true)
    .then((userId) => {
      client
        .query<{ user: User }>({
          query: getUserQuery,
          variables: { id: userId },
        })
        .then(({ data: { user } }) => {
          const managersAreas =
            user.parkingAreaManagers?.collection.map(
              ({ role, parkingArea: { id } }) => {
                return {
                  areaId: id,
                  role: role,
                };
              }
            ) || [];
          const userAreas =
            user?.parkingAreas?.collection.map(({ id }) => ({
              areaId: id,
              role: ["ROLE_PARTNER"],
            })) || [];

          AreaRoles.areas.set([...managersAreas, ...userAreas]);
          AppState.set({
            isUserAuthenticated: true,
            isStarting: false,
            user: user,
          });
        })
        .catch((error) => {
          AppState.isStarting.set(false);
          popoverAlert({ msg: error.message, icon: "error" });
        });
    })
    .catch(() => {
      AppState.isStarting.set(false);
    });
} else {
  AppState.isStarting.set(false);
}

export default client;
