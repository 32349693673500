exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutus-tsx": () => import("./../../../src/pages/aboutus.tsx" /* webpackChunkName: "component---src-pages-aboutus-tsx" */),
  "component---src-pages-add-[type]-tsx": () => import("./../../../src/pages/add/[type].tsx" /* webpackChunkName: "component---src-pages-add-[type]-tsx" */),
  "component---src-pages-add-complaint-[areaid]-tsx": () => import("./../../../src/pages/add/complaint/[areaid].tsx" /* webpackChunkName: "component---src-pages-add-complaint-[areaid]-tsx" */),
  "component---src-pages-addmanager-[areaid]-[type]-tsx": () => import("./../../../src/pages/addmanager/[areaid]/[type].tsx" /* webpackChunkName: "component---src-pages-addmanager-[areaid]-[type]-tsx" */),
  "component---src-pages-addresidence-[areaid]-tsx": () => import("./../../../src/pages/addresidence/[areaid].tsx" /* webpackChunkName: "component---src-pages-addresidence-[areaid]-tsx" */),
  "component---src-pages-addvisitor-[areaid]-[gateid]-tsx": () => import("./../../../src/pages/addvisitor/[areaid]/[gateid].tsx" /* webpackChunkName: "component---src-pages-addvisitor-[areaid]-[gateid]-tsx" */),
  "component---src-pages-bookings-tsx": () => import("./../../../src/pages/bookings.tsx" /* webpackChunkName: "component---src-pages-bookings-tsx" */),
  "component---src-pages-complaintdetails-[areaid]-[complaintid]-tsx": () => import("./../../../src/pages/complaintdetails/[areaid]/[complaintid].tsx" /* webpackChunkName: "component---src-pages-complaintdetails-[areaid]-[complaintid]-tsx" */),
  "component---src-pages-corporateinvitation-[id]-tsx": () => import("./../../../src/pages/corporateinvitation/[id].tsx" /* webpackChunkName: "component---src-pages-corporateinvitation-[id]-tsx" */),
  "component---src-pages-corporateinvitation-tsx": () => import("./../../../src/pages/corporateinvitation.tsx" /* webpackChunkName: "component---src-pages-corporateinvitation-tsx" */),
  "component---src-pages-edit-[type]-[id]-tsx": () => import("./../../../src/pages/edit/[type]/[id].tsx" /* webpackChunkName: "component---src-pages-edit-[type]-[id]-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-howitworks-tsx": () => import("./../../../src/pages/howitworks.tsx" /* webpackChunkName: "component---src-pages-howitworks-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invitation-[id]-tsx": () => import("./../../../src/pages/invitation/[id].tsx" /* webpackChunkName: "component---src-pages-invitation-[id]-tsx" */),
  "component---src-pages-invitation-tsx": () => import("./../../../src/pages/invitation.tsx" /* webpackChunkName: "component---src-pages-invitation-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-otp-user-id-tsx": () => import("./../../../src/pages/otp/[userId].tsx" /* webpackChunkName: "component---src-pages-otp-user-id-tsx" */),
  "component---src-pages-oversignt-[id]-tsx": () => import("./../../../src/pages/oversignt/[id].tsx" /* webpackChunkName: "component---src-pages-oversignt-[id]-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-residencedetail-[residenceid]-tsx": () => import("./../../../src/pages/residencedetail/[residenceid].tsx" /* webpackChunkName: "component---src-pages-residencedetail-[residenceid]-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-society-[section]-[areaid]-[areaname]-[managerid]-manager-type-tsx": () => import("./../../../src/pages/society/[section]/[areaid]/[areaname]/[managerid]/[managerType].tsx" /* webpackChunkName: "component---src-pages-society-[section]-[areaid]-[areaname]-[managerid]-manager-type-tsx" */),
  "component---src-pages-statistics-tsx": () => import("./../../../src/pages/statistics.tsx" /* webpackChunkName: "component---src-pages-statistics-tsx" */),
  "component---src-pages-transaction-tsx": () => import("./../../../src/pages/transaction.tsx" /* webpackChunkName: "component---src-pages-transaction-tsx" */)
}

