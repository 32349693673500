import { gql } from "@apollo/client";

export const createUserQuery = gql`
  mutation createUser(
    $name: String
    $email: String
    $contactNumber: String!
    $password: String
  ) {
    createUser(
      input: {
        name: $name
        email: $email
        contactNumber: $contactNumber
        password: $password
        roles: ["ROLE_PARTNER"]
      }
    ) {
      user {
        id
        name
        email
        contactNumber
        status {
          code
        }
      }
    }
  }
`;

export const getUserByContactInfoQuery = gql`
  query getUser($contactNumber: String, $email: String) {
    users(contactNumber: $contactNumber, email: $email, isPartner: true) {
      edges {
        node {
          id
          name
          roles
          contactNumber
        }
      }
    }
  }
`;

export const getUserQuery = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      email
      name
      roles
      contactNumber
      isVerified
      gateManagers {
        collection {
          id
          gate {
            id
          }
          parkingArea {
            id
          }
        }
      }
      userAddresses {
        collection {
          id
          address
          city {
            id
            name
          }
          state {
            id
            name
          }
          pincode {
            id
            pincode
          }
        }
      }
      gender {
        id
        code
      }
      sponsor {
        id
      }
      parkingAreaManagers(status_code: "ACTIVE") {
        collection {
          id
          role
          parkingArea {
            id
            name
          }
        }
      }
      parkingAreas(
        status_code_list: [
          "DEACTIVE"
          "ACTIVE"
          "PENDING"
          "REVIEW"
          "REJECTED"
        ]
      ) {
        collection {
          id
        }
      }
      userMedias {
        collection {
          id
          mediaUrl
        }
      }
    }
  }
`;

// export const getUserCustomQuery = gql``;

export const GetUserAddress = gql`
  query userAddress($user: Int) {
    userAddresses(user_id: $user) {
      collection {
        id
        user {
          id
        }
        address
        city {
          id
          name
        }
        state {
          id
          name
        }
        pincode {
          id
          pincode
        }
      }
    }
  }
`;

export const UpdateUserQuery = gql`
  mutation updateUser(
    $id: ID!
    $contactNumber: String
    $email: String
    $name: String
  ) {
    updateUser(
      input: {
        id: $id
        email: $email
        name: $name
        contactNumber: $contactNumber
      }
    ) {
      user {
        id
        email
        name
        roles
        contactNumber
        isVerified
        userAddresses {
          collection {
            id
            address
            city {
              id
              name
            }
            state {
              id
              name
            }
            pincode {
              id
              pincode
            }
          }
        }
        gender {
          id
          code
        }
        sponsor {
          id
        }
        parkingAreaManagers(status_code: "ACTIVE") {
          collection {
            id
            role
            parkingArea {
              id
              name
            }
          }
        }
        userMedias {
          collection {
            id
            mediaUrl
          }
        }
      }
    }
  }
`;

export const CreateUserAddressQuery = gql`
  mutation createUserAddress(
    $user: String!
    $address: String!
    $city: String!
    $state: String!
    $pincode: String!
  ) {
    createUserAddress(
      input: {
        user: $user
        address: $address
        city: $city
        state: $state
        pincode: $pincode
        country: "/countries/107"
      }
    ) {
      userAddress {
        id
        address
        city {
          name
        }
        state {
          name
        }
        pincode {
          pincode
        }
      }
    }
  }
`;

export const UpdateUserAddressQuery = gql`
  mutation updateUserAddress(
    $id: ID!
    $address: String
    $city: String
    $state: String
    $pincode: String
  ) {
    updateUserAddress(
      input: {
        id: $id
        address: $address
        city: $city
        state: $state
        pincode: $pincode
      }
    ) {
      userAddress {
        id
        address
        city {
          name
        }
        state {
          name
        }
        pincode {
          pincode
        }
      }
    }
  }
`;

export const GetDomesticWorkers = gql`
  query GetDomesticWorker(
    $role: String
    $parkingArea: String
    $status: String
    $order: [ParkingAreaManagerFilter_order]
    $status_list: [String]
    $page: Int
  ) {
    parkingAreaManagers(
      role: $role
      parkingArea: $parkingArea
      status_code: $status
      order: $order
      status_code_list: $status_list
      page: $page
    ) {
      collection {
        id
        role
        admin {
          id
          name
        }
        manager {
          id
          roles
          contactNumber
          name
          email
          userMedias {
            collection {
              id
              mediaUrl
            }
          }
        }
      }
      paginationInfo {
        lastPage
      }
    }
  }
`;
