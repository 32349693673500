import { createState } from "@hookstate/core";
import { Persistence } from "@hookstate/persistence";
import { TokenInfo } from "../Interfaces/Token";

export interface IAuthState {
  token?: string;
  refreshToken?: string;
  tokenInfo?: TokenInfo;
}

export const initialAuthState: IAuthState = {
  token: undefined,
  refreshToken: undefined,
  tokenInfo: undefined,
};
const AuthState = createState({ ...initialAuthState });

if (typeof window !== "undefined") {
  AuthState.attach(Persistence("plugin-persisted-data-key"));
}

export default AuthState;
