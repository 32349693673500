import { createState } from "@hookstate/core";
import { User } from "../Interfaces/User";

interface IAppState {
  isUserAuthenticated: boolean;
  user?: User;
  isStarting: boolean;
}

export const initalAppState: IAppState = {
  isUserAuthenticated: false,
  user: undefined,
  isStarting: true,
};

const AppState = createState({ ...initalAppState });

export default AppState;
