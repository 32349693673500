import Swal from "sweetalert2";

interface Props {
  msg: string;
  icon: "warning" | "error" | "success";
  title?: string;
}

const popoverAlert = ({ msg, icon, title }: Props) => {
  Swal.fire({
    text: msg || "Oops something went wrong",
    type: icon,
    title,
  });
};

export { popoverAlert };
