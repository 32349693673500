import { createState } from "@hookstate/core";

interface IAreaRoles {
  areas: {
    role: string[];
    areaId: string;
  }[];
}

export const initialAreaRoles: IAreaRoles = {
  areas: [],
};

const AreaRoles = createState({ ...initialAreaRoles });

export default AreaRoles;
