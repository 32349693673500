import axios, { AxiosRequestConfig } from "axios";
import AuthState from "../States/Auth";

const baseUrl = process.env.GATSBY_API_SERVER;

const Server = {
  get: function (url: string, params?: AxiosRequestConfig["params"]) {
    return new Promise((resolve, reject) => {
      const config: AxiosRequestConfig = {
        params: params,
        headers: {
          accept: "application/json",
          authorization: AuthState.token.get()
            ? `Bearer ${AuthState.token.get()}`
            : "",
        },
      };
      axios
        .get(`${baseUrl}${url}`, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  post: function (
    url: string,
    data?: any,
    params?: AxiosRequestConfig["params"]
  ) {
    return new Promise((resolve, reject) => {
      const config: AxiosRequestConfig = {
        params: params,
        headers: {
          accept: "application/json",
          authorization: AuthState.token.get()
            ? `Bearer ${AuthState.token.get()}`
            : "",
        },
      };

      axios
        .post(`${baseUrl}${url}`, { ...data }, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  put: function (url: string, file: FormData) {
    const config: AxiosRequestConfig = {
      headers: {
        "content-Type": "image/jpeg",
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`${url}`, file.get("file"), config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  patch: function (url: string, params?: any) {
    const config: AxiosRequestConfig = {
      headers: {
        accept: "application/json",
        authorization: AuthState.token.get()
          ? `Bearer ${AuthState.token.get()}`
          : "",
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .patch(`${baseUrl}${url}`, params, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  del: function (url: string) {
    const config: AxiosRequestConfig = {
      headers: {
        accept: "application/json",
        authorization: AuthState.token.get()
          ? `Bearer ${AuthState.token.get()}`
          : "",
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .delete(`${baseUrl}${url}`, config)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default Server;
