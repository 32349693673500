import JwtDecode from "jwt-decode";

import AppState from "../States/AppState";
import { initalAppState } from "../States/AppState";
import AuthState, { IAuthState, initialAuthState } from "../States/Auth";

import { TokenInfo } from "../Interfaces/Token";
import moment from "moment";
import Server from "./Server";
import { User } from "../Interfaces/User";

let timeOut: NodeJS.Timeout;

const Login = (token: IAuthState, isRefreshNow: boolean) => {
  refreshToken(isRefreshNow, token?.refreshToken, token?.tokenInfo?.exp);
  return new Promise((resolve) => {
    if (token.token) {
      const tokenData: TokenInfo = JwtDecode(token?.token);
      if (tokenData) {
        if (new Date(tokenData.exp * 1000) < new Date()) {
          AppState.set({ isStarting: false, isUserAuthenticated: false });
          AuthState.token.set(undefined);
          AuthState.tokenInfo.set(undefined);
          return;
        } else {
          AppState.set({ isStarting: false, isUserAuthenticated: true });
          AuthState.set({ ...token, tokenInfo: tokenData });
          resolve(`users/${tokenData.username}`);
          return;
        }
      } else {
        AppState.set({ ...initalAppState, isStarting: false });
        AuthState.set({ ...initialAuthState });
      }
    }
  });
};

const refreshToken = (isNoDelay: boolean, rft?: string, exp?: number) => {
  let diff = 8630000;
  if (exp) {
    const end = moment(exp * 1000).toISOString();
    const now = moment().toISOString();
    diff = moment(end).diff(now, "millisecond") - 100000;
  }
  timeOut = setTimeout(
    () => {
      Server.post("/token/refresh", { refreshToken: rft })
        .then((res: any) => {
          const tokenData: TokenInfo = JwtDecode(res?.token);
          AuthState.set({ ...res, tokenInfo: tokenData });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    isNoDelay ? 1 : diff
  );
};

const RoleLogin = (user: User): string => {
  const areas = user?.parkingAreaManagers?.collection;
  const areaId =
    user?.parkingAreaManagers?.collection[0]?.parkingArea?.id?.split("/")[2];
  const areaName = user?.parkingAreaManagers?.collection[0]?.parkingArea?.name;
  const shortUserId = user?.id?.split("/")[2];

  if (!areas?.length) return "/";
  if (user?.parkingAreas?.collection?.length && areas?.length) return "/";

  switch (true) {
    case user?.roles?.includes("ROLE_HELPER"):
      return areas && areas?.length === 1
        ? `/society/helpdesk/${areaId}/${areaName}/${shortUserId}/helper`
        : "/";

    case user?.roles?.includes("ROLE_MANAGER_HELPDESK"):
      return areas && areas.length === 1
        ? `/society/helpdesk/${areaId}/${areaName}/_/helpdesk`
        : "/";

    case user?.roles?.includes("ROLE_GATEKEEPER"):
      if (areas && areas.length === 1) {
        const myGates = user.gateManagers?.collection.filter(
          ({ parkingArea }) => parkingArea.id === areas[0]?.parkingArea.id
        );
        if (myGates && myGates?.length > 1) {
          return `/society/gates/${
            areas[0]?.parkingArea.id.split("/")[2]
          }/${encodeURIComponent(areas[0]?.parkingArea.name)}/${
            user.id?.split("/")[2]
          }/gatekeeper`;
        }
        if (myGates && myGates.length === 1) {
          return `/society/visitor/${
            areas[0]?.parkingArea.id.split("/")[2]
          }/${encodeURIComponent(areas[0]?.parkingArea.name)}/${
            myGates[0]?.gate.id.split("/")[2]
          }/gatekeeper`;
        }
        return "/";
      } else {
        return "/";
      }
    default:
      return "/";
  }

  // switch (true) {
  //   case user?.roles?.includes("ROLE_HELPER"):
  //     return areas && areas?.length === 1
  //       ? `/society/helpdesk/${areaId}/${areaName}/${shortUserId}/helper`
  //       : "/";
  //   case user?.roles?.includes("ROLE_MANAGER_HELPDESK"):
  //     return areas && areas.length === 1
  //       ? `/society/helpdesk/${areaId}/${areaName}/_/helpdesk`
  //       : "/";
  //   case user?.roles?.includes("ROLE_GATEKEEPER"):
  //     if (areas && areas.length === 1) {
  //       const myGates = user.gateManagers?.collection.filter(
  //         ({ parkingArea }) => parkingArea.id === areas[0]?.parkingArea.id
  //       );
  //       if (myGates && myGates.length === 1) {
  //         return `/society/visitor/${
  //           areas[0]?.parkingArea.id.split("/")[2]
  //         }/${encodeURIComponent(areas[0]?.parkingArea.name)}/${
  //           myGates[0]?.gate.id.split("/")[2]
  //         }/gatekeeper`;
  //       }
  //       return "/";
  //     } else {
  //       return "/";
  //     }
  //   default:
  //     return "/";
  // }
};
const Logout = () => {
  return new Promise((resolve) => {
    AppState.set({ ...initalAppState, isStarting: false });
    AuthState.set({ ...initialAuthState });
    clearTimeout(timeOut);
    resolve(true);
  });
};

export { Login, Logout, RoleLogin };
